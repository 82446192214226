import { createSlice, createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import { getWithExpiry, loadState } from 'utils/localStorage';
import calculateProgress from 'utils/helpers/getProgressPercentage';
import bifurcateBy from 'utils/helpers/bifurcateBy';
import { isSessionEndDateOrAfter } from 'utils/helpers/momentHelpers';

const initialState = {
  user: null,
  // load the token from localStorage if it exists
  scrumToken: getWithExpiry('scrumToken'),
  accessToken: loadState('accessToken') || null,
  refreshToken: loadState('refreshToken') || null,
  firstLogon: false,
  progress: null,
  loading: false,
  error: null,
  signingIn: false,
  pending: null,
  isImpersonating: !!getWithExpiry('scrumToken'),
  rememberMe: loadState('rememberMe') || false,
  rememberedEmail: loadState('rememberedEmail') || null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          user,
          accessToken,
          refreshToken,
          firstLogin = false,
          features,
        },
      }
    ) => {
      state.user = { ...user, features };
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.firstLogin = firstLogin;
      state.error = null;
    },
    setUser: (state, { payload: { user } }) => {
      state.user = user;
      state.error = null;
    },
    setAchievements: (state, { payload = [] }) => {
      state.user.achievements = payload;
    },
    setUserPhoto: (state, { payload }) => {
      state.user = { ...state.user, image: payload };
    },
    startImpersonating: (state, { payload: { user, accessToken } }) => {
      state.scrumToken = state.accessToken;
      state.user = user;
      state.accessToken = accessToken;
      state.isImpersonating = true;
      state.error = null;
    },
    stopImpersonating: (state) => {
      state.user = null;
      state.accessToken = state.scrumToken;
      state.scrumToken = null;
      state.isImpersonating = false;
      state.error = null;
    },
    logoutUser: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.scrumToken = null;
      state.isImpersonating = false;
      state.signingIn = false;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload || null;
    },
    addRating: (state, { payload }) => {
      state.user.ratings = [...state.user.ratings, payload];
    },
    toggleOptIn: (state, { payload: fieldName }) => {
      if (typeof state?.user[fieldName] === 'boolean') {
        state.user[fieldName] = !state.user[fieldName];
      }
    },
    setProgress: (state, { payload: { progress } }) => {
      state.user.progress = progress;
    },
    setSessions: (state, { payload: { sessions } }) => {
      state.user.sessions = sessions;
    },
    setOnDemandCourse: (state, { payload: course }) => {
      const index = state.user?.on_demand_courses?.findIndex(
        // eslint-disable-next-line camelcase
        ({ _id }) => _id === course._id
      );
      if (index === -1) state.user.on_demand_courses.push(course);
      else state.user.on_demand_courses[index] = course;
    },
    setModules: (state, { payload: modules }) => {
      if (state.user?.modules) {
        state.user.modules = modules;
      }
    },
    toggleRememberMe: (state, { payload: rememberMe = false }) => {
      state.rememberMe = rememberMe;
    },
    setRememberedEmail: (state, { payload: email }) => {
      state.rememberedEmail = state.rememberMe ? email : null;
    },
    setUserLanguage: (state, { payload: language }) => {
      state.user.language = language;
    },
    updateModules: (state, { payload: modules = [] }) => {
      state.user.modules = modules;
    },
  },
});

export const {
  addRating,
  logoutUser,
  setCredentials,
  setError,
  setModules,
  setOnDemandCourse,
  setProgress,
  setSessions,
  setRememberedEmail,
  setUser,
  setUserPhoto,
  setUserLanguage,
  startImpersonating,
  stopImpersonating,
  toggleOptIn,
  toggleRememberMe,
} = authSlice.actions;

export const actions = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;

export const selectProgress = createSelector(
  selectCurrentUser,
  (user) => user?.progress || {}
);

export const selectOnDemandCourses = createSelector(
  selectCurrentUser,
  (user) => user?.on_demand_courses || []
);

export const selectTeamCourses = createSelector(
  selectCurrentUser,
  (user) => user?.teamCourses || []
);

export const selectSessions = createSelector(
  selectCurrentUser,
  (user) => user?.sessions || []
);

export const selectBifurcatedSessions = createSelector(
  selectCurrentUser,
  (user) => {
    return bifurcateBy(user.sessions, (session) => {
      const isScheduled = moment(session.date_start).isAfter(moment(), 'day');
      if (isScheduled) {
        return false;
      } else {
        const sessionModules = session?.modules || [];
        // some legacy sessions have empty courses array

        const sessionCoursesModules = [];

        for (const courseModule of session.courses[0]?.modules || []) {
          if (courseModule.module_type === 'SectionModule') {
            for (const module of courseModule.module_id.modules) {
              sessionCoursesModules.push(module);
            }
          } else {
            sessionCoursesModules.push(courseModule);
          }
        }

        const inProgress = isSessionEndDateOrAfter(session.date_end);
        const { total, complete } = calculateProgress({
          user,
          modules: [...sessionModules, ...sessionCoursesModules],
          inProgress,
          sessionName: session.name,
          sessionId: session._id,
        });
        return complete === total;
      }
    });
  }
);

export const selectInProgressCount = createSelector(
  selectBifurcatedSessions,
  ([, inProgressSessions]) => inProgressSessions?.length || 0
);

export const selectAchievements = createSelector(
  selectCurrentUser,
  (user) => user?.achievements || []
);

export const selectPartnerModules = createSelector(
  selectCurrentUser,
  (user) => user?.partner?.modules || []
);

export const selectRenewalExams = createSelector(
  selectCurrentUser,
  (user) => user?.modules || []
);

export const selectBifurcatedRenewalExams = createSelector(
  selectCurrentUser,
  selectRenewalExams,
  (user, renewalExams) => {
    return bifurcateBy(renewalExams, (renewalExam) => {
      const isQuizModule = renewalExam?.module_type === 'QuizModule';
      const progress = user?.progress?.[renewalExam.module_id._id];
      const isPassed = isQuizModule ? !!progress?.passed : false;
      const isCompleted = isQuizModule && isPassed;
      return isCompleted;
    });
  }
);

export const selectRenewalExamsCount = createSelector(
  selectProgress,
  selectRenewalExams,
  (progress, renewalExams) => {
    const badgeCount = renewalExams.reduce((count, { module_id: { _id } }) => {
      const moduleProgress = progress[_id];
      if (!moduleProgress?.passed) {
        count = count + 1;
      }
      return count;
    }, 0);

    return badgeCount;
  }
);

export const selectUnlockedRenewalExamsCount = createSelector(
  selectProgress,
  selectRenewalExams,
  (progress, renewalExams) => {
    const badgeCount = renewalExams.reduce(
      (count, { module_id: { _id, renewal_stripe_payment_id } }) => {
        const moduleProgress = progress[_id];
        if (!moduleProgress?.passed && renewal_stripe_payment_id) {
          count = count + 1;
        }
        return count;
      },
      0
    );

    return badgeCount;
  }
);

export const selectIsActiveTrainer = createSelector(
  selectAchievements,
  (achievements = []) =>
    achievements
      .filter(({ name }) => /trainer/i.test(name))
      .some(({ date_expires }) =>
        moment(date_expires).isSameOrAfter(moment(), 'day')
      )
);

export const selectAppRole = (state) => state.auth.user?.role;

export const selectIsAdmin = (state) =>
  state.auth.user?.role === 'admin' || false;

export const selectIsManager = (state) =>
  state.auth.user?.role === 'manager' || false;

export const selectOrganization = (state) => state.auth.user?.organization;

export const selectSubscription = (state) => {
  if (state.auth.user?.subscriptions?.length > 0) {
    return true;
  }
  if (state.auth.user?.companies.length > 0) {
    for (const company of state.auth.user.companies) {
      if (company?.subscriptions?.length > 0) {
        return true;
      }
    }
  }
};

export const selectCompanyId = (state) => {
  const companies = state.auth.user?.companies;

  if (!companies?.length) return null;

  const firstCompany = companies.find((company) => company.role !== 'student');

  return firstCompany?.company?._id;
};

export const selectTeamId = (state) =>
  state.auth.user?.companies?.length &&
  state.auth.user?.companies[0]?.team?._id;

export const selectCompanyRole = (state) => {
  if (state.auth.user?.companies?.length) {
    if (state.auth.user?.companies?.length === 1) {
      return state.auth.user?.companies[0].role;
    }
    let roleToReturn = 'student';
    const userCompanies = state.auth.user?.companies || [];
    for (const { role } of userCompanies) {
      if (role === 'consultant') {
        roleToReturn = 'consultant';
        break; // highest role found, no need to continue
      }
      if (role === 'companyAdmin' && roleToReturn !== 'consultant') {
        roleToReturn = 'companyAdmin';
      }
      if (
        role === 'teamAdmin' &&
        roleToReturn !== 'companyAdmin' &&
        roleToReturn !== 'consultant'
      ) {
        roleToReturn = 'teamAdmin';
      }
    }

    return roleToReturn;
  }
};

export const selectHasRenewalExperiment = (state) =>
  state.auth.user.experiments.findIndex(
    ({ name }) => name === 'Renewal Cadence'
  ) >= 0;

export const selectHasMultipleCompanies = (state) =>
  state.auth.user?.companies?.filter(
    ({ active, role }) => active && role === 'companyAdmin'
  )?.length > 1;

export const selectors = {
  selectCurrentUser,
  selectProgress,
  selectOnDemandCourses,
  selectTeamCourses,
  selectSessions,
  selectBifurcatedSessions,
  selectInProgressCount,
  selectAchievements,
  selectPartnerModules,
  selectRenewalExams,
  selectBifurcatedRenewalExams,
  selectRenewalExamsCount,
  selectUnlockedRenewalExamsCount,
  selectIsActiveTrainer,
  selectIsAdmin,
  selectCompanyId,
  selectTeamId,
  selectCompanyRole,
  selectHasRenewalExperiment,
  selectHasMultipleCompanies,
};
